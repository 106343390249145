<template>
  <div class="container with-sidebar">
    <Sidebar />
    <div>
      <h1>Nueva Dehesa</h1>
      <p>
        En España una Dehesa es un tipo de bosque en el que predominan los robles (Quercus ilex, Quercus suber). La
        Dehesa es hecho por el hombre durante siglos al usar el bosque y lo que produce para muchas actividades
        agrícolas diferentes incluyendo el pastoreo de ganado. El cerdo ibérico es parte integrante de la Dehesa ya que
        se alimenta durante el otoño y el invierno en las bellotas de los robles.
      </p>
      <p>
        Desafortunadamente, en los últimos años, el pastoreo insuficiente y excesivo y otros problemas han llevado a
        <router-link :to="{ name: 'desertification' }"> desertificación</router-link> y lo que una vez fue un sistema
        agroforestal altamente productivo ahora está en declive. Lo que lo empeora es también el hecho de que los viejos
        robles también se están muriendo como consecuencia.
      </p>

      <h2>Nuevas formas</h2>
      <p>
        Hemos ideado nuevas formas de mantener la idea de la Dehesa y restaurar la fertilidad del suelo mientras
        creamos también un sistema que es muy productivo.
      </p>
      <p>
        En lugar de utilizar el método de poblamiento fijo para el ganado (una cantidad fija de animales por hectárea
        durante un período muy largo) usamos alta densidad de población (muchos animales en un espacio pequeño por un
        corto período de tiempo) y un sistema de caminos para animales y áreas de pequeño tamaño que juegan diferentes
        roles a lo largo del año.
      </p>

      <picture>
        <source media="(max-width: 500px)" srcset="/img/concept/animalCropPasture.png">
        <img src="/img/concept/animalCropPasture.png" />
      </picture>

      <p>
        Dependiendo de sus instintos naturales, los animales levantan el suelo (cerdos) o se alimentan y abren la
        superficie con sus pezuñas. Caminan libres, solo guiados por caminos y puertas de animales, y van a un pozo de
        agua de la misma manera que lo hacen los animales salvajes.
      </p>
      <p>
        Después de que los cerdos hayan desarraigado un terreno sembramos en él un policultivo de plantas para que haya
        nuevo forraje para los bovinos y cerdos. Una vez que el cultivo está listo para la cosecha, los bovinos comen
        las hojas y los cerdos van por el cultivo de raíces.
      </p>
      <p>
        Esta es solo una descripción general rápida de nuestro concepto. No está <em>grabado en piedra</em> y evoluciona
        a medida que aprendemos de observación. Nuestro principal objetivo es restaurar la fertilidad del suelo para
        crear abundancia para que eventualmente podamos cosechar el excedente.
      </p>
      <p>
        Además de lo anterior, también agregamos muchas especies de plantas nuevas como cultivo o plantamos muchos
        árboles adicionales para sombra y biomasa y también como forraje.
      </p>

    </div>
  </div>
</template>

<script>
import Sidebar from '@/views/concept/Sidebar.vue';
export default {
  name: "DehesaES",
  components: {
    Sidebar
  },
};
</script>
